<template>
  <div>
    <v-app-bar
      relative
      elevate-on-scrol
      flat
      scroll-target="#scrolling-techniques-7"
      color="accent"
      dark
    >
      <v-btn icon @click="goback()" class="menuBtn"
        ><v-icon>mdi-arrow-left</v-icon></v-btn
      >
    </v-app-bar>
  </div>
</template>

<script>
export default {
  props: {
    urlBack: {
      default: "",
      String,
    },
  },

  methods: {
    goback() {
      this.$emit("goback");
    },
  },
};
</script>
